import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ImgArrowRight, ImgPersonOutline } from '../../images';
import ToggleSwtichComponent from './toggleSwitchComponent';
import '../../styles/datasetAccessComponent.scss';
import { useDispatch } from 'react-redux';
import { datasetShareEnableDisble } from '../../actions/datasetActions';

export const DatasetAccessComponent = (props) => {
    const { data, datasetId, onEditAccess } = props;
    const dispatch = useDispatch();
    const [shareViewOpen, setShareViewOpen] = useState(false);

    const updateIsEnabled = (e) => {
        const { uid } = data;
        dispatch(datasetShareEnableDisble(uid, datasetId, e.target.checked));
    }

    const getAccessType = (data) => {
        try {
            if (data.access_type) {
                return data.access_type.charAt(0).toUpperCase() + data.access_type.slice(1).toLowerCase();
            } else {
                return data.is_downloadable ? 'Editor' : 'Viewer'
            }
        } catch {
            return 'Viewer'
        }
    }

    return (
        <div className='access-info'>
            <div className={shareViewOpen ? 'name-type open' : 'name-type'}>
                <img src={ImgPersonOutline} />
                <div className='user'>
                    <span className='type'>{getAccessType(data)}</span>
                    <span className='username text-truncate'>{`${data.first_name} ${data.last_name}`}</span>
                </div>
                <img src={ImgArrowRight} onClick={() => setShareViewOpen(!shareViewOpen)} className={shareViewOpen ? 'collapse-control open' : 'collapse-control'} />
            </div>
            <Collapse isOpen={shareViewOpen}>
                <div className="access">
                    <div className='edit-access' onClick={onEditAccess}>Edit Access</div>
                    <div className='toggle-access'>Enabled <ToggleSwtichComponent checked={data.is_enabled} onChangeHandler={updateIsEnabled} /></div>
                </div>
            </Collapse>
        </div>
    )
}

export default DatasetAccessComponent;