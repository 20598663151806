import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { DummyImgUploadDataset, ImgAddLarge, ImgCog, ImgCross, ImgDelete, ImgDownload, ImgExpand, ImgInfo, ImgInfoSm, ImgMoreHoriz, ImgOMTLogo, ImgPencil, ImgPreview, ImgPreviewSmall, ImgShare, Logo } from '../images';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as datasetActions from '../actions/datasetActions';
import HeaderComponent from './pure/headerComponent';
import '../styles/myDatasetsComponent.scss';
import '../styles/dsgSearchComponent.scss';
import HgDropdownComponent from './pure/hgDropdownComponent';
import moment from 'moment/moment';
import { Collapse, Table } from 'reactstrap';
import { calculateMemorySize, formatDate, parseDatasetSchema } from '../utils/utils';
import HgButtonComponent from './pure/hgButtonComponent';
import axios from 'axios';
import SidebarComponent from './pure/sidebarComponent';
import { DATASET_STATUS, DOCUMENT_ROOT, SIDEBAR_TABS, VISIBILITY, VISIBILITY_TYPE } from '../config';
import BackToMainComponent from './pure/backToMainComponent';
import SafeHtmlComponent from './pure/safeHtmlComponent';
import DatasetVersionsComponent from './pure/datasetVersionsComponent';
import DatasetPreviewComponent from './pure/datasetPreviewComponent';
import Loader from './loaderComponent';
import ShareDatasetComponent from './pure/shareDatasetComponent';
import DatasetAccessComponent from './pure/datasetAccessComponent';
import DatasetColumnDetailsComponent from './pure/datasetColumnDetailsPopupComponent';
import DatasetColumnDetailsPopComponent from './pure/datasetColumnDetailsPopupComponent';
import DatasetDescriptionPopupComponent from './pure/datasetDescriptionPopupComponent';

class ViewDatasetsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearched: false,
            preview_table_header: [],
            preview_table_data: [],
            datasetSchema: [],
            versionModal: false,
            isPreviewLoading: false,
            isColumnDetailsLoading: false,
            shareDatasetModal: false,
            columnDetailsModal: false,
            desciptionModal: false, 
            isDeleteModalOpen: false,
            datasetToDelete: null
        }


    }


    toggleDeleteModal = (dataset) => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen,
            datasetToDelete: dataset
        });
    }
    
    closeDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: false,
            datasetToDelete: null
        });
    }
    handleDeleteDataset = () => {
        const { datasetToDelete } = this.state;
        if (datasetToDelete) {
            this.props.actions.updateDatasetStatus(datasetToDelete.uid, DATASET_STATUS.DELETED, () => {
                this.props.history.push(`/my-datasets`);
            });
            this.closeDeleteModal();
        }
    }

    updateDatasetStatus = (dataset, status) => {
        this.props.actions.updateDatasetStatus(dataset.uid, status, () => {
            this.props.history.push(`/my-datasets`);
        });
    }
    componentDidMount() {
        const { uid } = this.props.match.params;
        this.props.actions.getRootDatasetInfo(uid, (previewUrl, Schema_Url) => {
            this.getPreview(previewUrl);
            this.getDatasetSchema(Schema_Url);
        });
        this.props.actions.getDatasetVerionsList(uid, VISIBILITY.PRIVATE, DATASET_STATUS.PROCESSED);
        this.props.actions.getDatasetSharedUsers(uid);
    }

    componentWillUnmount() {
        // this.props.actions.getRootDatasetInfoReset();
    }

    getPreview = (url) => {
        if (url) {
            this.setState({ isPreviewLoading: true });
            axios.get(url).then(({ data }) => {
                this.setState({ preview_table_header: Object.keys(data[0]), preview_table_data: [...data] });
            }).catch(error => {
                this.setState({ preview_table_header: [], preview_table_data: [] });
            }).finally(() => {
                this.setState({ isPreviewLoading: false });
            });
        } else {

        }
    }

    getDatasetSchema = (url) => {
        if (url) {
            this.setState({ isColumnDetailsLoading: true });
            axios.get(url).then(({ data }) => {
                this.setState({ datasetSchema: data });
            }).catch(error => {
                this.setState({ datasetSchema: [] });
            }).finally(() => {
                this.setState({ isColumnDetailsLoading: false });
            });
        } else {

        }
    }

    searchModel = () => {

    }

    onChangeSearch = () => {

    }

    selectDatasetHandler = (e, selectedDatasetUid) => {
        const checked = e.target.checked;
        if (checked) {
            const selectedDatasets = [...this.state.selectedDatasets, selectedDatasetUid]
            this.setState({ selectedDatasets })
        } else {
            const fitleredSelectedDatasets = this.state.selectedDatasets.filter(item => item !== selectedDatasetUid);
            this.setState({ selectedDatasets: fitleredSelectedDatasets })
        }

        console.log(this.state.selectedDatasets);
        console.log(e.target.checked);

    }

    selectedDatasetToViewInfo = (item) => {
        this.setState({ selectedDatasetToView: [item] });
    }

    toggleCollapse = (item) => {
        switch (item) {
            case 'description':
                this.setState({ descOpen: !this.state.descOpen });
                break;
            case 'actions':
                this.setState({ descActions: !this.state.descActions });
                break;
            case 'shareDataset':
                this.setState({ shareDatasetModal: !this.state.shareDatasetModal });
                break;
            case 'columnDetails':
                this.setState({ columnDetailsModal: !this.state.columnDetailsModal });
                break;
            case 'desciptionModal':
                this.setState({ desciptionModal: !this.state.desciptionModal });
                break;
            default:
                break;
        }
    }

    openViewDateset = (uid) => {
        this.props.history.push(`view-dataset/${uid}`);
    }

    openAddSet = () => {
        this.props.history.push(`add-dataset`)
    }

    onEditDataset = (uid) => {
        this.props.history.push(`/edit-dataset/${uid}`)
    }

    updateDatasetStatus = (dataset, status) => {
        this.props.actions.updateDatasetStatus(dataset.uid, status, () => {
            this.props.history.push(`/my-datasets`);
        });
    }
   
    toggleVersionsPopup = () => {
        this.setState({ versionModal: !this.state.versionModal });
    }

    getVersionInfo = (item) => {
        console.log(item);
        if (item.uid) {
            this.setState({ preview_table_header: [], preview_table_data: [] });
            this.props.actions.getDatasetVersionInfo(item.uid, this.getPreview);
            this.toggleVersionsPopup();
        }
    }

    onShareDataset = (rootDatasetId, params) => {
        this.props.actions.shareDataset(rootDatasetId, params, () => {
            this.toggleCollapse('shareDataset');
        });
    }

    render() {
        const childDataset = this.props.dataset.datasetInfo.latest_version;
        const rootDataset = this.props.dataset.datasetInfo;
        return (
            <div className='my-datasets-component'>
                <HeaderComponent />
                <SidebarComponent activeTab={SIDEBAR_TABS.myDataSet} />
                <div className='main'>
                    <section className='section-3'>
                        <BackToMainComponent onclickHandler={() => this.props.history.push(`${DOCUMENT_ROOT}my-datasets`)} />
                    </section>
                    <section className='section-4'>
                        <div className='name-desc'>
                            <div className='dataset-name' style={{ padding: 0 }}>
                                <div className='heading'>MY DATASETS / DETAILS/</div>
                                <div className='name' onClick={this.toggleVersionsPopup}>{rootDataset.name} - <span className='version'>Version {childDataset.version}</span></div>
                            </div>
                        </div>
                    </section>
                    <section className='section-4-1'>
                        <section className='section-4'>
                            <div className='general-info'>
                                <div className='name-desc'>
                                    {/* <div className='dataset-name'>
                                <div className='heading'>MY DATASETS / DETAILS/</div>
                                <div className='name'>{rootDataset.name}</div>
                            </div> */}
                                    <div className='creator-name sh-dd'>
                                        <div className='heading'>dataset owner</div>
                                        <div className='name text-truncate'>{rootDataset.creator_name}</div>
                                    </div>
                                    <div className='description' style={{ marginTop: 0 }}>
                                        <div className='heading'>Dataset Description</div>
                                        <div className='content'>
                                            <SafeHtmlComponent data={childDataset.description} />
                                            {childDataset.description.length > 450 && <div className='show-more' onClick={() => this.toggleCollapse('desciptionModal')}>show more</div>}
                                        </div>
                                    </div>
                                    {/* <div className='org sh-dd'>
                                        <div className='heading'>tags</div>
                                        <div className='name tags'>{childDataset.tags.map(item => <span key={item} className='tag'>{item}</span>)}</div>
                                    </div> */}
                                </div>

                                {/* <div className='owner'> */}
                                {/* <div className='creator-name sh-dd'>
                                        <div className='heading'>dataset owner</div>
                                        <div className='name text-truncate'>{rootDataset.creator_name}</div>
                                    </div> */}
                                {/* <div className='org-cr'> */}
                                {/* <div className='org sh-dd'>
                                            <div className='heading'>organisation</div>
                                            <div className='name'> - </div>
                                        </div> */}
                                {/* <div className='cr-date sh-dd'>
                                    <div className='heading'>created</div>
                                    <div className='name'>{formatDate(rootDataset.creation_date)}</div>
                                </div> */}
                                {/* </div> */}
                                {/* <div className='ver-up'>
                                        <div className='version sh-dd'>
                                    <div className='heading'>version</div>
                                    <div className='name'>{childDataset.version}</div>
                                </div>
                                        <div className='up-date sh-dd' style={{marginLeft: 0}}>
                                    <div className='heading'>updated</div>
                                    <div className='name'>{formatDate(rootDataset.last_modified_date)}</div>
                                </div>
                                    </div> */}
                                {/* </div> */}

                                <div className='dataset-info'>
                                    <div className='org sh-dd'>
                                        <div className='heading'>organisation</div>
                                        <div className='name'> - </div>
                                    </div>
                                    <div className='size-rw-col'>
                                        <div className='size sh-dd'>
                                            <div className='heading'>size</div>
                                            <div className='name' style={{ fontSize: '16px' }}>{calculateMemorySize(childDataset.dataset_size)}</div>
                                        </div>
                                        <div className='rows sh-dd'>
                                            <div className='heading'>rows</div>
                                            <div className='name'>{childDataset.dataset_records_count}</div>
                                        </div>
                                        <div className='columns sh-dd'>
                                            <div className='heading'>columns</div>
                                            <div className='name'>{childDataset.dataset_columns_count}</div>
                                        </div>
                                    </div>
                                    <div className='size-rw-col'>
                                        <div className='cr-date sh-dd'>
                                            <div className='heading'>created</div>
                                            <div className='name'>{formatDate(childDataset.creation_date)}</div>
                                        </div>
                                        <div className='up-date sh-dd'>
                                            <div className='heading'>updated</div>
                                            <div className='name'>{formatDate(childDataset.last_modified_date)}</div>
                                        </div>
                                        <div className='up-date sh-dd'>
                                            <div className='heading'>visibility</div>
                                            <div className='name'>{rootDataset.visibility}</div>
                                        </div>
                                        {/* <div className='rows sh-dd'>
                                    <div className='heading'>rows</div>
                                    <div className='name'>{childDataset.dataset_records_count}</div>
                                </div>
                                <div className='columns sh-dd'>
                                    <div className='heading'>columns</div>
                                    <div className='name'>{childDataset.dataset_columns_count}</div>
                                </div> */}
                                    </div>
                                    <div className='org sh-dd'>
                                        <div className='heading'>status</div>
                                        <div className='name'>{childDataset.status}</div>
                                    </div>

                                </div>
                                <div className='dataset-access'>
                <div className='actions sh-dd'>
                    <div className='handlers'>
                        {rootDataset.shared_on 
                            ? <img src={ImgPencil} style={{opacity: '0.5', cursor: 'not-allowed'}} /> 
                            : <img src={ImgPencil} onClick={() => this.onEditDataset(rootDataset.uid)} />}
                        <a href={childDataset.s3_url} target="_self"> <img src={ImgDownload} title="Download" /> </a>
                        {/* {(rootDataset.is_shared || rootDataset?.latest_version?.status !== "PROCESSED") 
                            ? <img src={ImgShare} style={{opacity: '0.5', cursor: 'not-allowed'}} /> 
                            : <img src={ImgShare} title="Share" onClick={() => this.toggleCollapse('shareDataset')} />} */}
                        {rootDataset.is_shared || rootDataset?.latest_version?.status !== "PROCESSED"
                          ? (
                            rootDataset?.latest_version?.status === "PROCESSING_FAILED" 
                          ? (
                            <img 
                            src={ImgShare} 
                            style={{ opacity: '0.5', cursor: 'not-allowed' }} 
                            title="Cannot share because the dataset could not be processed." 
                          />
                        ) : (
                           <img 
                             src={ImgShare} 
                             style={{ opacity: '0.5', cursor: 'not-allowed' }} 
                           />
                        )
                        ) : (
                           <img 
                             src={ImgShare} 
                             title="Share" 
                             onClick={() => this.toggleCollapse('shareDataset')} 
                           />
                        )
                        }
                        {rootDataset.shared_on 
                            ? <img src={ImgDelete} title="Delete Dataset" style={{opacity: '0.5', cursor: 'not-allowed'}} /> 
                            : <img src={ImgDelete} title="Delete Dataset" onClick={() => this.toggleDeleteModal(rootDataset)} />}
                    </div>
                </div>
                {!rootDataset.shared_on && this.props.dataset.datasetSharedWithUsers.length > 0 
                    ? <div className='dataset-users sh-dd' style={{ padding: '14px 0', maxHeight: '233px', minHeight: '72px', overflowY: 'auto' }}>
                        {this.props.dataset.datasetSharedWithUsers.map(item => {
                            return <DatasetAccessComponent key={item.uid} data={item} datasetId={rootDataset.uid} />
                        })}
                    </div>
                    : <div className='dataset-users sh-dd' style={{height: "calc(77px * 3)", flexDirection: 'row', gap: '8px', fontSize: '14px'}}>
                        <img src={ImgInfoSm} width="24px" height="24px" /> Dataset is not shared with anyone yet. 
                    </div>}
                <Modal style={{width: '349px', top:"24px", left:"20px", padding:"16px"}} isOpen={this.state.isDeleteModalOpen} onRequestClose={this.closeDeleteModal} size={"md"} className={"share-dataset-component"}>
                    <ModalHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height:"32px" }}>
                        <span style={{ flex: '2', fontSize:"11px", fontWeight: 500, lineHeight:"16px" }}>Delete Confirmation</span>
                        {/* <RxCross1 style={{ cursor: 'pointer', marginLeft: '210px', color: 'grey', fontSize:'20px' }} onClick={this.closeDeleteModal} onMouseOver={(e) => { e.target.style.color = 'black'; }} onMouseOut={(e) => { e.target.style.color = 'grey'; }} /> */}
                    </ModalHeader>
                    <ModalBody>
                        <div className='gen-info' style={{ width: '100%', fontSize:"16px", lineHeight:"24px", wordWrap:"break-word", color:"#1A1C1E" }} >
                            Are you sure you want to delete the selected dataset?
                        </div>
                        <div  style={{  fontSize:"11px", lineHeight:"24px", color:"#93000A", fontWeight: 500, lineHeight:"16px", height:"16px" }} >
                               This action cannot be undone.
                              </div>
                    </ModalBody>
                    <ModalFooter style={{ padding: '10px' }}>
                        <HgButtonComponent buttonText={"CANCEL"} isPrimary={false} onclickHandler={this.closeDeleteModal} />
                        <HgButtonComponent buttonText={"DELETE"} isPrimary={true} onclickHandler={this.handleDeleteDataset} type="submit" />
                    </ModalFooter>
                </Modal>
            </div>
                            </div>
                            <section className='section-5'>
                                <div className='ds-name'>{`${childDataset.name} (columns ${childDataset.dataset_columns_count})`} <img src={ImgPreviewSmall} onClick={() => this.toggleCollapse('columnDetails')} /></div>
                                {this.state.isPreviewLoading
                                    ? <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>Dataset Preview Loading <Loader /></div>
                                    : <div className='table-container'><DatasetPreviewComponent preview_table_data={this.state.preview_table_data} preview_table_header={this.state.preview_table_header} /></div>}
                            </section>
                        </section>

                    </section>
                </div>
                <DatasetVersionsComponent
                    data={this.props.dataset.childDatasets}
                    toggle={() => this.toggleVersionsPopup()}
                    modal={this.state.versionModal}
                    onAccept={this.getVersionInfo}
                />
                <ShareDatasetComponent
                    rootDatasetId={rootDataset.uid}
                    toggle={() => this.toggleCollapse('shareDataset')}
                    modal={this.state.shareDatasetModal}
                    onAccept={() => this.toggleCollapse('shareDataset')}
                    onSubmit={this.onShareDataset}
                />
                <DatasetColumnDetailsPopComponent
                    datasetSchema={this.state.datasetSchema}
                    toggle={() => this.toggleCollapse('columnDetails')}
                    modal={this.state.columnDetailsModal}
                    onAccept={() => this.toggleCollapse('columnDetails')}
                />
                <DatasetDescriptionPopupComponent
                    description={rootDataset.description}
                    toggle={() => this.toggleCollapse('desciptionModal')}
                    modal={this.state.desciptionModal}
                    onAccept={() => this.toggleCollapse('desciptionModal')}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {
        isDatasetsListLoading,
        datasetInfo,
        childDatasets,
        datasetSharedWithUsers
    } = state.dataset
    return {
        dataset: { isDatasetsListLoading, datasetInfo, childDatasets, datasetSharedWithUsers }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(datasetActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDatasetsComponent);