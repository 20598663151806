// import PullToRefresh from 'pulltorefreshjs';

import moment from "moment";

/**
 * @function b64EncodeUnicode
 * @param {string} str 
 */
export function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}

/**
 * @function b64DecodeUnicode
 * @param {string} str 
 */
export function b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

/**
 * @function isEmpty Check isEmpty
 * @param {any} data 
 */
export function isEmpty(data) {
    if (typeof (data) === 'object') {
        if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
            return true;
        } else if (!data) {
            return true;
        } else {
            return false;
        }
    } else if (typeof (data) === 'string') {
        if (!data.trim()) {
            return true;
        }
        return false;
    } else if (typeof (data) === 'undefined') {
        return true;
    } else {
        return false;
    }
}

/**
 * @function scrollPaginator infinite scroll
 * @param {function} handleObserver 
 */
export function scrollPaginator(handleObserver = () => { }) {
    try {
        if (typeof handleObserver !== 'function') {
            throw "Pangination handler mmissing, Expected function!";
        }
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        };
        return new IntersectionObserver(
            handleObserver,
            options
        )
    }
    catch (error) {
        console.log("Scroll Paginator: ", error);
        return new IntersectionObserver(() => { })
    }
}

// /**
//  * @function pullToRefrsh
//  * @param {function} onRefresh 
//  */
// export function pullToRefrsh(onRefresh) {
//     const config = {
//         mainElement: 'body',
//         distIgnore: 10,
//         onRefresh: typeof onRefresh === 'function' ? onRefresh : () => { }
//     }
//     try {
//         if (typeof onRefresh !== 'function') {
//             throw "Refresh handler missing, Expected function!"
//         }
//     }
//     catch (error) {
//         console.log("Pull to Refresh: ", error);
//     }
//     return PullToRefresh.init(config);
// }

/**
 * 
 * @param {number} bytes 
 * @returns string
 */
export function calculateMemorySize(bytes) {
    if (bytes) {
        const sizeInKB = Math.round(((bytes / 1000) + Number.EPSILON) * 100) / 100;
        const sizeInMB = Math.round(((sizeInKB / 1000) + Number.EPSILON) * 100) / 100;
        const sizeInGB = Math.round(((sizeInMB / 1000) + Number.EPSILON) * 100) / 100;
        if (sizeInKB < 1000) {
            return `${sizeInKB} KB`;
        } else if (sizeInKB > 1000 && sizeInMB < 1000) {
            return `${sizeInMB} MB`;
        } else {
            return `${sizeInGB} GB`;
        }
    }
    return "-";
}

export function formatDate(date) {
    if (date) {
        try {
            return moment(date).format('DD MMM YY');
        } catch (e) {
            console.log(`Could not format date ${date}`);
            return "-";
        }
    }
    return "-";
}

/**
 * Get file format
 * @param {string} name 
 * @returns file extension
 */
export function getFileFormat(name) {
    try {
        const temp = name.split('.');
        return temp[temp.length - 1];
    } catch (e) {
        console.log("Exception occured: ", e);
    }
}

export function getVersionString(item) {
    try {
        if (item.version && item.version_alias) {
            return `${item.version} - ${item.version_alias}`;
        }
        return `${item.version}`;
    } catch {
        return "-";
    }
}

export function parseDatasetSchema(schema) {
    // console.log(schema)
    try {
        let parsedSchema = '';
        parsedSchema = JSON.parse(schema);
        parsedSchema = JSON.parse(schema);
        parsedSchema = JSON.parse(schema);
        if (typeof parsedSchema === 'string') {
            parsedSchema = JSON.parse(schema);
        }
        return parsedSchema;
    } catch (e) {
        console.log(e);
    }
    return schema;
}
