import React from 'react';
import '../../styles/toggleSwitchComponent.scss';

export const ToggleSwtichComponent = (props) => {
    const { square, checked, onChangeHandler } = props;

    return (<>
        <label className="switch">
            <input type="checkbox"
                defaultChecked={checked}
                // value={checked} 
                onChange={onChangeHandler} />
            <span className={square ? "slider" : "slider round"}></span>
        </label>
    </>);
}

export default ToggleSwtichComponent;