import React, { useEffect, useRef, useState } from 'react';
// import { search } from '../../images';
import Fuse from 'fuse.js';
import '../../styles/hgDropdownComponent.scss';


export const HgDropdownComponent = (props) => {
    const { listItems, customListComponent, customSelectionComponent, onSelectHandler, enableSearch, searchKeys, autoSelected, defaultValue, placeholder, listIdentifier, customClass, isMultiSelect } = props;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelectedItem] = useState({ label: '', value: '' });
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const searchInputRef = useRef(null);
    const fuse = new Fuse(listItems, {
        keys: searchKeys || []
    });

    const handleDefaultValue = () => {
        try {
            if (defaultValue) {
                if (typeof defaultValue === 'string') {
                    if (listItems.findIndex(item => item.value === defaultValue) !== -1) {
                        const [defaultItem] = listItems.filter(item => item.value === defaultValue);
                        setSelectedItem(defaultItem);
                    } else {
                        throw 'Default item passed not present in list of items';
                    }
                } else if (typeof defaultValue === 'object') {
                    if (listItems.findIndex(item => item.value === defaultValue.value) !== -1) {
                        const [defaultItem] = listItems.filter(item => item.value === defaultValue.value);
                        setSelectedItem(defaultItem);
                    } else {
                        throw 'Default object passed not present in list of items';
                    }
                } else {
                    throw 'Default Value of type ' + typeof defaultValue + ' not supported. Only string and object.label is supported';
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onSelectItem = (item) => {
        if (isMultiSelect) {
            setSelectedItems.push(item);
        } else {
            setSelectedItem(item);
        }
        onSelectHandler(item, listIdentifier);
        setDropdownOpen(false);
    }

    const onSearch = (e) => {
        const { name, value } = e.target;
        setSearchResults(fuse.search(value));
    }

    const getListItem = () => {
        if (enableSearch) {
            if (searchInputRef && searchInputRef.current && searchInputRef.current.value) {
                return (
                    searchResults.map(({ item, i }) => <li key={`${item.value}${i}`} onClick={() => onSelectItem(item)}>
                        {
                            !customListComponent
                                ? item.label
                                : customListComponent(item)
                        }
                    </li>)
                )
            }
        }

        return (listItems.map((item, i) => {
            let optionObj = {}
            if (typeof item === 'string') {
                optionObj = {
                    label: item,
                    value: item
                }
            } else {
                optionObj = item;
            }
         return <li key={`${optionObj.value}${i}`} onClick={() => onSelectItem(optionObj)}>
            {
                !customListComponent
                    ? optionObj.label
                    : customListComponent(optionObj)
            }
        </li>}))
    }

    const getPlaceholderText = () => {
        if (placeholder) {
            return <div style={{color: '#577692'}}>{placeholder}</div>
        }
        return <div style={{color: '#577692'}}>Select</div>
    }

    useEffect(() => {
        if (autoSelected) {
            if (autoSelected.value.toLowerCase() !== selected.value.toLowerCase()) {
                setSelectedItem(autoSelected);
            }
        }
    }, [autoSelected]);

    useEffect(() => {
        handleDefaultValue();
    }, [defaultValue])

    return (
        <div className={customClass ? `dsg-dropdown ${customClass}` : "dsg-dropdown"}>
            <div className={isDropdownOpen ? "selected-item open" : "selected-item"}
                onClick={() => setDropdownOpen(!isDropdownOpen)}>{
                    selected.label && !["Status", "Type"].includes(placeholder)
                        ? !customSelectionComponent ? selected.label : customSelectionComponent(selected)
                        : getPlaceholderText()}</div>
            {isDropdownOpen && <div className="dropdown-toggler" onClick={() => setDropdownOpen(false)}></div>}
            {isDropdownOpen && <div className="dropdown-list">
                <ul>
                    {enableSearch && <div className="search-field hg-form">
                        <div className="form-field">
                            <input type="text" className="form-control" placeholder="search for…" onChange={onSearch} ref={searchInputRef} />
                            {/* <span className="search-icon"><img src={search} width="20" height="20" /></span> */}
                        </div>
                    </div>}
                    {getListItem()}
                </ul>
            </div>}
        </div>
    )

}

export default HgDropdownComponent;