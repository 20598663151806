import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PUBLIC_PATHS } from './config';
import SessionUtils from './utils/sessionUtils';
import LoginComponent from './components/loginComponent';
import SingupComponent from './components/signupComponent';
import VerifyEmailComponent from './components/verifyEmailComponent';
import ResetPasswordComponent from './components/resetPasswordComponent';
import SetResetPasswordComponent from './components/setResetPasswordComponent';
import LoginSuccessComponent from './components/loginSuccessComponent';
import MyDatasetsComponent from './components/myDatasetsComponent';
import AddDatasetComponent from './components/addDatasetComponent';
import AddDatasetComponentCopy from './components/addDatasetComponentCopy';
import ViewDatasetsComponent from './components/viewDatasetsComponent';
import PublicDatasetsComponent from './components/publicDatasetsComponent';
import AddDatasetVersionComponent from './components/addDatasetVersionComponent';
import ManageAccountComponent from './components/manageAccountComponent';
import DummySupersetComponent from './components/pure/dummySupersetComponent';
import AccountSetting from './components/accountSeeting';
// import MyDashBoardComponent from "./components/dashboardComponent"


const pathsWithoutRefreshToken = PUBLIC_PATHS;
const PrivateRoute = ({ component, ...rest }) => {
  const isAuthed = SessionUtils.getToken('refresh_token');

  return (
    <Route {...rest} exact
      render={(props) => {
        const publicPath = pathsWithoutRefreshToken.findIndex(item => item === props.location.pathname || props.location.pathname.startsWith('/verify-email/') || props.location.pathname.startsWith('/reset/')); 
        return (
          isAuthed ? (
            publicPath !== -1
              ? <Redirect
                to={{
                  pathname: '/my-datasets',
                  state: { from: props.location }
                }}
              />
              : <>
                {React.createElement(component, props)}
              </>
          ) :
            (
              publicPath === -1
                ? <Redirect
                  to={{
                    // pathname: '/sign-up',
                    pathname: props.location.pathname,
                    state: { from: props.location }
                  }}
                />
                : <>{React.createElement(component, props)}</>
            )
        )
      }}
    />
  )
}

function App(props) {
  return (
    <ConnectedRouter history={props.history}>
      <Switch>
        <PrivateRoute exact={true} path={"/"} component={LoginComponent} />
        <PrivateRoute path={"/sign-up"} component={SingupComponent} />
        <PrivateRoute path={"/verify-email/:key"} component={VerifyEmailComponent} />
        <PrivateRoute path={"/reset/:token"} component={SetResetPasswordComponent} />
        <PrivateRoute path={"/reset-password"} component={ResetPasswordComponent} />
        <PrivateRoute path={"/manage-account"} component={ManageAccountComponent} />
        <PrivateRoute path={"/add-dataset"} component={AddDatasetComponentCopy} />
        {/* <PrivateRoute path={"/add-dataset-copy"} component={AddDatasetComponentCopy} /> */}
        <PrivateRoute path={"/my-datasets"} component={MyDatasetsComponent} />
        <PrivateRoute path={"/view-dataset/:uid"} component={ViewDatasetsComponent} />
        <PrivateRoute path={"/edit-dataset/:uid"} component={AddDatasetComponent} />
        <PrivateRoute path={"/public-datasets"} component={PublicDatasetsComponent} />
        <PrivateRoute path={"/add-dataset-version/:uid"} component={AddDatasetVersionComponent} />
        <PrivateRoute path={"/account-seeting"} component={AccountSetting} />
        {/* <PrivateRoute path={"/analytics/dashboard/list"} component={MyDashBoardComponent} />
        <PrivateRoute path={"/analytics/chart/list"} component={DummySupersetComponent} />  */}
      </Switch>
    </ConnectedRouter>
  );
}
export default App;


