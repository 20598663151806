import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../styles/modalComponent.scss';
import { getVersionString } from '../../utils/utils';
import DatasetPreviewComponent from './datasetPreviewComponent';
import HgButtonComponent from './hgButtonComponent';
import SafeHtmlComponent from './safeHtmlComponent';

function DatasetDescriptionPopupComponent(props) {
    const { description, toggle,  modal, onAccept} = props

    return (
            <Modal isOpen={modal} toggle={toggle} size={"md"} scrollable={true} {...props}>
                <ModalHeader toggle={toggle}>Dataset Description</ModalHeader>
                <ModalBody>
                    <div className='pd-8'><SafeHtmlComponent data={description} /></div>
                </ModalBody>
                <ModalFooter><HgButtonComponent buttonText={"Close"} isPrimary={false} onclickHandler={() => onAccept()} /></ModalFooter>
            </Modal>
    );
}

export default DatasetDescriptionPopupComponent;