import React, { Component, createRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import HgButtonComponent from "./pure/hgButtonComponent";
import { RxCross1 } from "react-icons/rx";
import JSZip, { filter } from "jszip";
import { saveAs } from "file-saver";
import { IoClose } from "react-icons/io5";
import CustomTooltip from "./pure/toolTipComponent";
import styled from "styled-components";
import { Pagination } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";

import {
  DummyWidget1,
  DummyWidget2,
  ImgDelete,
  ImgDownload,
  ImgInfoSm,
  ImgMoreHoriz,
  ImgMystery,
  ImgPreview,
  ImgShare,
  ImgUpload,
} from "../images";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as datasetActions from "../actions/datasetActions";
import HeaderComponent from "./pure/headerComponent";
import "../styles/myDatasetsComponent.scss";
import "../styles/dsgSearchComponent.scss";
import moment from "moment/moment";
// import Modal from "react-modal"

import { Collapse } from "reactstrap";
import { calculateMemorySize, parseDatasetSchema } from "../utils/utils";
import SidebarComponent from "./pure/sidebarComponent";
import {
  DATASET_STATUS,
  MY_DATASET_RIGHT_PANEL_ACTIONS,
  SIDEBAR_TABS,
  VISIBILITY,
} from "../config";
import SafeHtmlComponent from "./pure/safeHtmlComponent";
import axios from "axios";
import Loader from "./loaderComponent";
import DatasetPreviewPopupComponent from "./pure/datasetPreviewPopupComponent";
import ShareDatasetComponent from "./pure/shareDatasetComponent";
import DatasetAccessComponent from "./pure/datasetAccessComponent";
import DatasetColumnDetailsComponent from "./pure/datasetColumnDetailsComponent";
import EditShareDatasetComponent from "./pure/editShareDatasetComponent";
import DsgSearchComponent from "./pure/dsgSearchComponent";
import multiDownload from "multi-download";
import {
  borderRadius,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from "@mui/system";
import AddDatasetComponentCopy from "./addDatasetComponentCopy";
// import { deleteBulkDataset } from "../api/datasetApi"

const DatasetName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  text-overflow: ellipsis;
`;
const trimDatasetName = (name) => {
  if (name.length > 17) {
    return name.substring(0, 15) + "...";
  }
  return name;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    padding: "20px",
    textAlign: "center",
    borderRadius: "10px",
  },
};
const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: '-50%',
    transform: "translate(-50%,20%)",
    padding: "20px",
    textAlign: "center",
    borderRadius: "10px",
  },
};

const CustomPagination = styled(Pagination)({
  "& .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
    backgroundColor: "#00639A",
  },
});

const CustomDialogContent = styled(DialogContent)({
  padding: "0px 0px",
});

class MyDatasetsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearched: false,
      appliedFitlers: [],
      selectedDatasets: [],
      selectedDatasetToView: [],
      descOpen: false,
      descActions: false,
      isOpenDataset: false,
      columnDetailsOpen: false,
      preview_table_header: [],
      preview_table_data: [],
      datasetSchema: [],
      previewModal: false,
      selectedRightPanelAction: MY_DATASET_RIGHT_PANEL_ACTIONS.OVERVIEW,
      shareDatasetModal: false,
      shareViewOpen: false,
      editShareDatasetModal: false,
      initialDatasetViewSet: false,
      selectedDatasetToViewInfo: this.selectedDatasetToViewInfo.bind(this),
      isSelectAllChecked: false,
      isOpen: false,
      selectedValue: "Selected",

      isDeleteModalOpen: false,
      selectedDatasets: [],
      isPermissionModalOpen: false,
      datasetsWithoutPermission: [],
      page: 1,
      filters: {},
      openModal: false,
      // selectedDatasetToView: []
    };
    this.handleClose = this.handleClose.bind(this);
    this.searchInputRef = createRef();
    this.appliedFilters = {};
    this.downloadDataset = createRef();
  }

  componentDidMount() {
    this.props.actions.getRootDatasetsList();
  }

  componentDidUpdate(prevProps, prevState) {
    const totalDatasets = this.props.dataset.rootDatasets.total_dataset;
    const pageSize = this.props.dataset.rootDatasets.page_size;

    const totalPages = Math.ceil(totalDatasets / pageSize);
    if (prevState.page !== this.state.page) {
      console.log("page:", this.state.page);
      this.props.actions.filterDatasets(
        VISIBILITY.PRIVATE,
        this.state.filters,
        this.state.page
      );
    }

    if (
      prevProps.totalDatasets !== totalDatasets &&
      this.state.page > totalPages
    ) {
      this.setState({ page: totalPages });
    }
  }

  componentWillUnmount() {
    this.props.actions.resetRootDatasetsList();
  }

  searchDatasets = (filters) => {
    this.setState({ filters });
    this.props.actions.filterDatasets(VISIBILITY.PRIVATE, filters);
  };

  searchModel = () => {};

  onChangeSearch = () => {};

  selectDatasetHandler = (e, selectedDatasetUid, selectedDataset) => {
    const checked = e.target.checked;
    // console.log("this is log for check",checked)
    const { rootDatasets } = this.props.dataset;
    // console.log("the dataset=> ",rootDatasets.results)
    if (selectedDatasetUid === "all") {
      // Bulk selection
      const allDatasetUids = rootDatasets.results.map((item) => item.uid);
      const updatedSelectedDatasets = checked ? allDatasetUids : [];

      this.setState({
        selectedDatasets: updatedSelectedDatasets,
        isSelectAllChecked: checked, // Set the state for "Select All" checkbox
      });
      if (updatedSelectedDatasets.length >= 1) {
        const selectedDatasetToView = rootDatasets.results.find(
          (item) => item.uid === updatedSelectedDatasets[0]
        );
        this.setState({ selectedDatasetToView: [selectedDatasetToView] });
      } else {
        this.setState({ selectedDatasetToView: [] });
      }
    } else {
      // Individual selection
      const { selectedDatasets } = this.state;
      if (checked) {
        this.selectedDatasetToViewInfo(selectedDataset);
        const updatedSelectedDatasets = [
          ...selectedDatasets,
          selectedDatasetUid,
        ];
        const allSelected =
          updatedSelectedDatasets.length === rootDatasets.results.length;
        this.setState({
          selectedDatasets: updatedSelectedDatasets,
          isSelectAllChecked: allSelected,
        });

        if (updatedSelectedDatasets.length === 1) {
          const selectedDatasetToView = rootDatasets.results.find(
            (item) => item.uid === selectedDatasetUid
          );
          this.setState({ selectedDatasetToView: [selectedDatasetToView] });
        }
      } else {
        const filteredSelectedDatasets = selectedDatasets.filter(
          (item) => item !== selectedDatasetUid
        );
        this.setState({
          selectedDatasets: filteredSelectedDatasets,
          isSelectAllChecked: false,
        });

        if (filteredSelectedDatasets.length === 0) {
          this.setState({ selectedDatasetToView: [] });
        }
      }
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleMultiDeleteAction = () => {
    const { selectedDatasets } = this.state;
    if (!this.state.datasetsWithoutPermission.length) {
      this.props.actions.updateDatasetStatus(
        selectedDatasets,
        DATASET_STATUS.DELETED,
        () => {
          this.setState({
            selectedDatasets: [],
            isSelectAllChecked: false,
            selectedDatasetToView: [],
          });
        }
      );
      this.setState({ isDeleteModalOpen: false });
    }
  };

  handleBulkDeletion() {
    const { selectedDatasets } = this.state;
    const { rootDatasets } = this.props.dataset;
    const filterDatasets = rootDatasets.results.filter((dataset) => {
      return selectedDatasets.find((datasetId) => {
        if (dataset.uid === datasetId) {
          return dataset;
        }
      });
    });
    const datasetsWithoutPermission = filterDatasets.filter(
      (dataset) => dataset.shared_on && dataset.access_type !== "ADMIN"
    );
    this.setState({ datasetsWithoutPermission: datasetsWithoutPermission });
    if (datasetsWithoutPermission.length > 0) {
      this.setState({
        isPermissionModalOpen: true,
        datasetsWithoutPermission,
      });
    } else {
      return this.setState({ isDeleteModalOpen: true });
    }
  }

  handleOptionClick = (value) => {
    switch (value) {
      case "Delete":
        this.handleBulkDeletion();

        break;
      case "Share":
        this.toggleCollapse("shareDataset");
        break;
      case "Download":
        this.multipleDataSetDownload();
        break;
      default:
        break;
    }
    this.setState({
      selectedValue: value,
      isOpen: false,
    });
  };

  handleChange = (event, value) => {
    if (this.state.page !== value) {
      this.setState({
        page: value,
        isSelectAllChecked: false,
        selectedDatasets: [],
      });
    } else {
      this.setState({ page: value });
    }
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  selectedDatasetToViewInfo = (item) => {
    console.log("Event Trigered::");
    this.setState({ selectedDatasetToView: [item] });
    this.getPreview(item.latest_version.dataset_preview_url);
    this.getDatasetSchema(item.latest_version.dataset_schema);
    this.props.actions.getDatasetSharedUsers(item.uid);
  };

  multipleDataSetDownload = () => {
    let selectedDataSets = this.getSelectedDatasetsToDownload();
    this.onDownloadDatasetHandler(selectedDataSets);
  };

  toggleCollapse = (item) => {
    switch (item) {
      case "description":
        this.setState({ descOpen: !this.state.descOpen });
        break;
      case "actions":
        this.setState({ descActions: !this.state.descActions });
        break;
      case "columnDetails":
        this.setState({ columnDetailsOpen: !this.state.columnDetailsOpen });
        break;
      case "datasetPreview":
        this.setState({ previewModal: !this.state.previewModal });
        break;
      case "shareDataset":
        this.setState({ shareDatasetModal: !this.state.shareDatasetModal });
        break;
      case "editShareDataset":
        this.setState({
          editShareDatasetModal: !this.state.editShareDatasetModal,
        });
        break;
      default:
        break;
    }
  };

  openViewDateset = (uid) => {
    this.props.history.push(`view-dataset/${uid}`);
  };

  addDatasetVersiob = (uid) => {
    this.props.history.push(`add-dataset-version/${uid}`);
  };

  openAddSet = () => {
    this.props.history.push(`add-dataset`);
  };

  openAddSetCopy = () => {
    this.setState({ openModal: true });
  };

  closeModal1 = () => {
    this.setState({ openModal: false });
  };

  //for single file download
  downloadFile = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });
      return response.data;
    } catch (error) {
      throw new Error(`Error downloading file from ${url}: ${error.message}`);
    }
  };
  async fetchFileAsBlob(url) {
    const response = await fetch(url);
    if (response) return response.blob();
  }

  async downloadZip(data) {
    try {
      const zip = new JSZip();

      //gives complete info of all the selected datasets this is done to get the names of the files along with there format while downloading
      const datasets = this.getSelectedDatasetsToDownload();

      // Fetch the file and add it to the zip one by one and once all the files are added then only initiate zip folder downloading
      await Promise.all(
        data.presigned_urls.map(async (fileUrl, index) => {
          const blob = await this.fetchFileAsBlob(fileUrl);
          console.log("Blob data::", blob);
          let currentFile = datasets[index];
          let fileName = currentFile.name;
          let fileFormat = currentFile.dataset_format;
          zip.file(`${fileName}.${fileFormat}`, blob);
        })
      );

      //After adding all the files

      // Generate the zip file and trigger download
      const content = await zip.generateAsync({ type: "blob" });

      //saving the file as the name "datasets.zip"
      saveAs(content, "datasets.zip");
    } catch (error) {
      console.error("Error downloading zip file:", error);
    }
  }

  onDownloadDatasetHandler = (datasets) => {
    console.log("Selected datasets are::::", datasets);
    const request = datasets.map((item) => ({
      child_dataset_id: item.latest_version.uid,
      dataset_format: item.latest_version.dataset_format,
    }));

    console.log("Requested data to download::", request);
    this.props.actions.downloadDataset(request, (data) => {
      //if there is only one file just download the file without creating zip
      if (data.presigned_urls.length === 1) {
        multiDownload(data.presigned_urls);
      } else {
        //for more than one file create zip folder
        this.downloadZip(data);
      }
    });
  };

  updateDatasetStatus = (dataset, status) => {
    this.props.actions.updateDatasetStatus([dataset.uid], status, () => {
      console.log(dataset.uid, "dataset.uid");
      this.setState({ selectedDatasetToView: [] });
    });
  };

  openModal = (dataset) => {
    if (dataset.shared_on && dataset.access_type !== "ADMIN") {
      this.setState({
        isPermissionModalOpen: true,
        datasetsWithoutPermission: [dataset],
      });
    } else {
      this.setState({ isModalOpen: true, datasetToDelete: dataset });
    }
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isPermissionModalOpen: false,
      datasetToDelete: null,
    });
  };
  closePermissionModal = () => {
    this.setState({ isPermissionModalOpen: false });
  };

  confirmDelete = () => {
    const { datasetToDelete } = this.state;
    if (datasetToDelete) {
      this.updateDatasetStatus(datasetToDelete, DATASET_STATUS.DELETED);
    }
    this.closeModal();
  };

  getPreview = (url) => {
    if (url) {
      this.setState({ preview_table_header: [], preview_table_data: [] });
      axios.get(url).then(({ data }) => {
        this.setState({
          preview_table_header: Object.keys(data[0]),
          preview_table_data: [...data],
        });
      });
    } else {
    }
  };

  getSelectedDatasetsToDownload = () => {
    const { selectedDatasets } = this.state;
    const datasets = selectedDatasets.map((currentSelectedDataset) => {
      return this.props.dataset.rootDatasets.results.find(
        (item) => item.uid === currentSelectedDataset
      );
    });
    return datasets;
  };

  getDatasetSchema = (url) => {
    if (url) {
      this.setState({ isColumnDetailsLoading: true });
      axios
        .get(url)
        .then(({ data }) => {
          this.setState({ datasetSchema: data });
        })
        .catch((error) => {
          this.setState({ datasetSchema: [] });
        })
        .finally(() => {
          this.setState({ isColumnDetailsLoading: false });
        });
    } else {
    }
  };

  onRightPanelActionSelect = (item) => {
    this.setState({ selectedRightPanelAction: item });
  };

  onShareDataset = (rootDatasetId, params) => {
    this.props.actions.shareDataset(rootDatasetId, params, () => {
      this.toggleCollapse("shareDataset");
    });
  };

  getColumnDetails = (values) => {
    try {
      let items = parseDatasetSchema(values);
      return items.map((value, index) => {
        const item = JSON.parse(value);
      });
    } catch {}
  };

  onShareDataset = (rootDatasetId, params) => {
    this.props.actions.shareDataset(
      rootDatasetId,
      params,
      this.state.selectedDatasetToView[0].status,
      () => {
        this.toggleCollapse("shareDataset");
      }
    );
  };

  onShareMultipleDataset = (rootDatasetId, params) => {
    const { selectedDatasets } = this.state;
    if (selectedDatasets.length > 0) {
      const shareRequest = [];

      for (let item of params) {
        for (let datasetId of selectedDatasets) {
          const shareObject = { ...item, root_dataset_id: datasetId };
          shareRequest.push(shareObject);
        }
      }
      this.props.actions.shareMultipleDataset(
        rootDatasetId,
        shareRequest,
        selectedDatasets,
        () => {
          this.toggleCollapse("shareDataset");
        }
      );
    }
  };

  getColumnDetails = (values) => {
    try {
      let items = parseDatasetSchema(values);
      return items.map((value, index) => {
        const item = JSON.parse(value);

        return (
          <li key={index}>
            <span className="col-name">{item["index"]}</span>
            <span className="col-type">{item["dtypes"]}</span>
          </li>
        );
      });
    } catch {
      return <></>;
    }
  };

  handleClose = () => {
    this.setState({ openModal: false }); // Close the modal
    console.log("Modal closed");
  };

  render() {
    const options = ["Download", "Share", "Delete"];
    const totalDatasets = this.props.dataset.rootDatasets.total_dataset;
    const pageSize = this.props.dataset.rootDatasets.page_size;
    const {
      isOpen,
      isDeleteModalOpen,
      selectedDatasets,
      isPermissionModalOpen,
      datasetsWithoutPermission,
    } = this.state;

    const { selectedDatasetToView, isModalOpen, openModal } = this.state;
    const datasetName = selectedDatasetToView.length
      ? selectedDatasetToView[0].name
      : "";
    // Inside the render method, add the new modal

    return (
      <div className="my-datasets-component">
        <HeaderComponent />
        <SidebarComponent activeTab={SIDEBAR_TABS.myDataSet} />
        <div className="main">
          <section className="section-1">
            <div className="heading">
              <div className="primary">datasets</div>
              <div className="secondary">my datasets</div>
            </div>

            <div className="seach-box">
              <DsgSearchComponent
                tagsChangeHandler={this.searchDatasets}
                defaultTags={""}
                visibility={VISIBILITY.PRIVATE}
              />
              <span className="form-icon search"></span>
            </div>
            <div className="custom-dropdown">
              <div
                className={`selected-value ${isOpen ? "open" : ""}`}
                onClick={
                  selectedDatasets.length > 0 ? this.toggleDropdown : null
                }
                style={{
                  cursor:
                    selectedDatasets.length > 0 ? "pointer" : "not-allowed",
                  opacity: selectedDatasets.length > 0 ? 1 : 0.5,
                }}
              >
                Selected{" "}
                {selectedDatasets.length > 0 ? selectedDatasets.length : ""}
              </div>
              {isOpen && (
                <div
                  className="dropdown-toggler"
                  onClick={() => this.toggleDropdown(false)}
                ></div>
              )}
              {isOpen && (
                <ul
                  className="dropdown-options"
                  style={{ textAlign: "left", textTransform: "capitalize" }}
                >
                  {options.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => this.handleOptionClick(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
              {isDeleteModalOpen && (
                <Modal
                  style={{
                    width: "349px",
                    top: "24px",
                    left: "20px",
                    padding: "16px",
                  }}
                  isOpen={isDeleteModalOpen}
                  onRequestClose={this.closeDeleteModal}
                  size={"md"}
                  className={"share-dataset-component"}
                >
                  <ModalHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "32px",
                    }}
                  >
                    <span
                      style={{
                        flex: "2",
                        fontSize: "11px",
                        fontWeight: 500,
                        lineHeight: "16px",
                      }}
                    >
                      Delete Confirmation
                    </span>
                    {/* <img src="/static/media/cross-rounded.c7f43de0.svg"  style={{ cursor: 'pointer' }} onClick={this.closeDeleteModal}></img> */}

                    {/* <RxCross1 style={{ cursor: 'pointer',  marginLeft: '220px', color: 'grey', fontSize: '20px' }} onClick={this.closeDeleteModal} onMouseOver={(e) => { e.target.style.color = 'black'; }} // Change color to white on hover
                      onMouseOut={(e) => { e.target.style.color = 'grey'; }} /> */}
                  </ModalHeader>
                  <ModalBody>
                    <div
                      className="gen-info"
                      style={{
                        width: "100%",
                        fontSize: "16px",
                        lineHeight: "24px",
                        height: "57px",
                        color: "#1A1C1E",
                      }}
                    >
                      Are you sure you want to delete the Selected Datasets?
                    </div>

                    <div
                      style={{
                        fontSize: "11px",
                        lineHeight: "24px",
                        color: "#93000A",
                        fontWeight: 500,
                        lineHeight: "16px",
                        height: "16px",
                      }}
                    >
                      This action cannot be undone.
                    </div>
                  </ModalBody>
                  <ModalFooter style={{ padding: "10px" }}>
                    <HgButtonComponent
                      buttonText={"CANCEL"}
                      isPrimary={false}
                      onclickHandler={this.closeDeleteModal}
                    />
                    <HgButtonComponent
                      buttonText={"DELETE"}
                      isPrimary={true}
                      onclickHandler={this.handleMultiDeleteAction}
                      type="submit"
                    />
                  </ModalFooter>
                </Modal>
              )}
            </div>

            {/* <div className="add-dataset" onClick={this.openAddSet}>
              Add Datasets
            </div> */}
            <div className="add-dataset" onClick={this.openAddSetCopy}>
              Add Datasets
            </div>

            {/* Modal component */}
            <Dialog
              open={openModal}
              onClose={this.closeModal1}
              maxWidth="md"
              fullWidth={true}
              PaperProps={{
                style: {
                  maxHeight: "87vh", // Constrain the height to avoid vertical overflow
                  width: "55vw", // Constrain the width to a reasonable size like 60% of the viewport width
                  margin: "auto", // Center the dialog horizontally
                  padding: 0, // Remove padding for full content use
                  overflow: "hidden", // Remove scrollbars if not needed
                  display: "flex",
                  // alignItems: 'end',
                },
              }}
              slotProps={{
                backdrop: {
                  style: {
                    backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent backdrop
                  },
                },
              }}
            >
              <CustomDialogContent>
                <AddDatasetComponentCopy handleClose={this.closeModal1} />
              </CustomDialogContent>
            </Dialog>
          </section>
          <section className="section-2">
            <div className="dataset-table">
              <div className="table-header">
                <div className="select-all">
                  <input
                    type="checkbox"
                    id="select-all"
                    name="remember-me"
                    hidden={true}
                    checked={this.state.isSelectAllChecked}
                    onChange={(e) => this.selectDatasetHandler(e, "all")}
                  />
                  <label htmlFor="select-all" className="dsg-checkbox"></label>
                </div>
                <div className="dataset-name">dataset name</div>
                <div className="dataset-format">type</div>
                {/* <div className='organisation'>organisation</div> */}
                <div className="creation-date">created on</div>
                <div className="last-modified-date">last modified</div>
                <div className="dateset-size">size</div>
                <div className="dataset-creator">created by</div>
                <div className="organisation">visibility</div>
                <div className="organisation">status</div>
              </div>

              {this.props.dataset.isDatasetsListLoading ? (
                <Loader customClass={"mg-top-10"} />
              ) : (
                <div className="table-data">
                  <ul>
                    {this.props.dataset.rootDatasets.results.map((item) => {
                      return (
                        <>
                          {item.latest_version.visibility === "PRIVATE" ||
                          item.latest_version.visibility === "PUBLIC" ? (
                            <li
                              key={item.uid}
                              className={
                                item.uid ===
                                (this.state.selectedDatasetToView.length &&
                                  this.state.selectedDatasetToView[0].uid)
                                  ? "selected"
                                  : ""
                              }
                            >
                              <div className="select">
                                <input
                                  type="checkbox"
                                  id={item.uid}
                                  name={item.uid}
                                  hidden={true}
                                  // onClick={() =>
                                  //   this.selectedDatasetToViewInfo(item)
                                  // }
                                  onChange={(e) =>
                                    this.selectDatasetHandler(e, item.uid, item)
                                  }
                                  checked={
                                    this.state.isSelectAllChecked ||
                                    this.state.selectedDatasets.includes(
                                      item.uid
                                    )
                                  }
                                />
                                <label
                                  htmlFor={item.uid}
                                  className="dsg-checkbox"
                                ></label>
                              </div>

                              <DatasetName className="dataset-name">
                                <CustomTooltip content={item.name}>
                                  {trimDatasetName(item.name)}
                                </CustomTooltip>
                              </DatasetName>

                              <div className="dataset-format">
                                {item.latest_version.dataset_format}
                              </div>
                              {/* <div className='organisation'>-</div> */}
                              <div className="creation-date">
                                {moment(item.creation_date).format(
                                  "DD MMM YYYY"
                                )}
                              </div>
                              <div className="last-modified-date">
                                {moment(item.creation_date).format(
                                  "DD MMM YYYY"
                                )}
                              </div>
                              <div className="dateset-size">
                                {calculateMemorySize(
                                  item.latest_version.dataset_size
                                )}
                              </div>
                              <div
                                className="dataset-creator"
                                title={item.creator_name}
                              >
                                {item.creator_name}
                              </div>
                              <div className="organisation">
                                {item.latest_version.visibility}
                              </div>
                              <div className="organisation">
                                {item.latest_version.status}
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ul>

                  <div className="pagination">
                    <CustomPagination
                      count={Math.ceil(totalDatasets / pageSize)}
                      // count={10}
                      color="primary"
                      page={this.state.page}
                      shape="rounded"
                      siblingCount={3}
                      boundaryCount={1}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.selectedDatasetToView.length > 0 && (
              <div className="right-panel">
                <div className="dataset-info">
                  <section className="actions bd-sh">
                    <div
                      className={
                        this.state.selectedRightPanelAction ===
                        MY_DATASET_RIGHT_PANEL_ACTIONS.OVERVIEW
                          ? "ac active"
                          : "ac"
                      }
                      onClick={() =>
                        this.onRightPanelActionSelect(
                          MY_DATASET_RIGHT_PANEL_ACTIONS.OVERVIEW
                        )
                      }
                    >
                      Overview
                    </div>
                    <div
                      className={
                        this.state.selectedRightPanelAction ===
                        MY_DATASET_RIGHT_PANEL_ACTIONS.ACCESS
                          ? "ac active"
                          : "ac"
                      }
                      onClick={() =>
                        this.onRightPanelActionSelect(
                          MY_DATASET_RIGHT_PANEL_ACTIONS.ACCESS
                        )
                      }
                    >
                      Access
                    </div>
                    <div
                      className={
                        this.state.selectedRightPanelAction ===
                        MY_DATASET_RIGHT_PANEL_ACTIONS.STATS
                          ? "ac active"
                          : "ac"
                      }
                      onClick={() =>
                        this.onRightPanelActionSelect(
                          MY_DATASET_RIGHT_PANEL_ACTIONS.STATS
                        )
                      }
                    >
                      Stats
                    </div>
                  </section>

                  <section className="dataset-desc">
                    <div className="dataset-actions bd-sh">
                      <div className="actions">
                        {this.state.selectedDatasetToView[0].shared_on ? (
                          <img
                            src={ImgUpload}
                            title="Upload Dataset Version"
                            style={{ opacity: "0.5", cursor: "not-allowed" }}
                          />
                        ) : (
                          <img
                            src={ImgUpload}
                            title="Upload Dataset Version"
                            onClick={() =>
                              this.addDatasetVersiob(
                                this.state.selectedDatasetToView[0].uid
                              )
                            }
                          />
                        )}
                        <img
                          src={ImgDownload}
                          title="Download Dataset"
                          onClick={() => this.multipleDataSetDownload()}
                        />
                        {this.state.selectedDatasetToView[0].shared_on ||
                        this.state.selectedDatasetToView[0]?.latest_version
                          ?.status !== "PROCESSED" ? (
                          this.state.selectedDatasetToView[0]?.latest_version
                            ?.status === "PROCESSING_FAILED" ? (
                            <img
                              src={ImgShare}
                              style={{ opacity: "0.5", cursor: "not-allowed" }}
                              title="Cannot share because the dataset could not be processed."
                            />
                          ) : (
                            <img
                              src={ImgShare}
                              style={{ opacity: "0.5", cursor: "not-allowed" }}
                            />
                          )
                        ) : (
                          <img
                            src={ImgShare}
                            title="Share Dataset"
                            onClick={() => this.toggleCollapse("shareDataset")}
                          />
                        )}
                        <div>
                          <img
                            src={ImgDelete}
                            title="Delete Dataset"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.openModal(
                                this.state.selectedDatasetToView[0]
                              )
                            }
                          />
                          <Modal
                            style={{
                              width: "349px",
                              top: "24px",
                              left: "20px",
                              padding: "16px",
                            }}
                            isOpen={isModalOpen}
                            onRequestClose={this.closeModal}
                            size={"md"}
                            className={"share-dataset-component"}
                            contentLabel="Confirm Delete Modal"
                          >
                            <ModalHeader
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "32px",
                              }}
                            >
                              <span
                                style={{
                                  flex: "2",
                                  fontSize: "11px",
                                  fontWeight: 500,
                                  lineHeight: "16px",
                                }}
                              >
                                Delete Confirmation
                              </span>
                              {/* <img src="/static/media/cross-rounded.c7f43de0.svg"  style={{ cursor: 'pointer' }} onClick={this.closeDeleteModal}></img> */}
                              {/* <RxCross1  style={{ cursor: 'pointer', marginLeft: '210px', color: 'grey', fontSize: '20px' }} onClick={this.closeModal}  onMouseOver={(e) => { e.target.style.color = 'black'; }} // Change color to white on hover
                                onMouseOut={(e) => { e.target.style.color = 'grey'; }} /> */}
                            </ModalHeader>
                            <ModalBody>
                              <div
                                className="gen-info"
                                style={{
                                  width: "100%",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  wordWrap: "break-word",
                                  color: "#1A1C1E",
                                }}
                              >
                                Are you sure you want to delete the{" "}
                                {datasetName}?
                              </div>
                              <div
                                style={{
                                  fontSize: "11px",
                                  lineHeight: "24px",
                                  color: "#93000A",
                                  fontWeight: 500,
                                  lineHeight: "16px",
                                  height: "16px",
                                }}
                              >
                                This action cannot be undone.
                              </div>
                            </ModalBody>
                            <ModalFooter style={{ padding: "10px" }}>
                              <HgButtonComponent
                                buttonText={"CANCEL"}
                                isPrimary={false}
                                onclickHandler={this.closeModal}
                              />
                              <HgButtonComponent
                                buttonText={"DELETE"}
                                isPrimary={true}
                                onclickHandler={this.confirmDelete}
                                type="submit"
                              />
                            </ModalFooter>
                          </Modal>

                          <Modal
                            style={{
                              width: "332px",
                              borderRadius: "8px",
                              position: "absolute",
                              top: "calc(50% - 1.75rem)",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                            isOpen={isPermissionModalOpen}
                            toggle={this.closePermissionModal}
                            size={"md"}
                            className=" share-dataset-component"
                            contentLabel="Permission Denied Modal"
                          >
                            <div
                              style={{
                                backgroundColor: "#FFF0EE",
                                borderRadius: "8px",
                                padding: "8px 8px 8px 18px",
                              }}
                            >
                              <ModalHeader
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "0px",
                                  border: "none",
                                  marginTop: "-10px",
                                }}
                              >
                                <span
                                  style={{
                                    flex: "2",
                                    fontSize: "11px",
                                    color: "#BA1A1A",
                                    fontWeight: 500,
                                    lineHeight: "16px",
                                    fontFamily: "Space Grotesk",
                                  }}
                                >
                                  DELETION FAILED
                                </span>
                                {/* <img src="/static/media/cross-rounded.c7f43de0.svg"  style={{ cursor: 'pointer' }} onClick={this.closeDeleteModal}></img> */}
                                <button
                                  onClick={this.closePermissionModal}
                                  style={{
                                    cursor: "pointer",
                                    background: "none",
                                    border: "none",
                                    marginLeft: "165px",
                                    fontSize: "24px",
                                    color: "#BA1A1A",
                                  }}
                                >
                                  <IoClose size={24} />
                                </button>
                              </ModalHeader>
                              <ModalBody>
                                <div
                                  className="gen-info"
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Space Mono",
                                    lineHeight: "24px",
                                    wordWrap: "break-word",
                                    marginLeft: "-15px",
                                    color: "#BA1A1A",
                                    marginTop: "-17px",
                                    fontWeight: 400,
                                    width: "98%",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  You do not have permission to delete the
                                  following:[
                                  {datasetsWithoutPermission.map(
                                    (dataset, index) => (
                                      <span key={dataset.uid}>
                                        {dataset.name}
                                        {index <
                                        datasetsWithoutPermission.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                  ]
                                </div>
                              </ModalBody>
                            </div>
                          </Modal>
                        </div>

                        {/* <a ref={this.downloadDataset} hidden></a> */}
                      </div>
                    </div>

                    <div className="name-desc bd-sh">
                      <div className="heading">dataset name</div>
                      <div
                        className="name"
                        title={this.state.selectedDatasetToView[0].name}
                      >
                        {this.state.selectedDatasetToView[0].name}
                      </div>

                      <div
                        className="show-desc"
                        onClick={() => this.toggleCollapse("description")}
                      >
                        {!this.state.descOpen ? "show" : "hide"} description
                      </div>
                      <Collapse isOpen={this.state.descOpen}>
                        <div className="description">
                          <SafeHtmlComponent
                            data={
                              this.state.selectedDatasetToView[0].description
                            }
                          />
                        </div>
                      </Collapse>
                    </div>
                    <div className="name-desc bd-sh">
                      <div
                        className="open-detail-view"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.openViewDateset(
                            this.state.selectedDatasetToView[0].uid
                          )
                        }
                      >
                        <img src={ImgPreview} /> open detail view
                      </div>
                    </div>

                    {this.state.selectedRightPanelAction ===
                      MY_DATASET_RIGHT_PANEL_ACTIONS.OVERVIEW && (
                      <>
                        <div className="org bd-sh">
                          <div className="heading">organisation</div>
                          <div className="name"> - </div>
                        </div>
                        <div className="owner-cr-date">
                          <div className="owner-name">
                            <div className="heading">owner</div>
                            <div className="name">
                              {this.state.selectedDatasetToView[0].creator_name}
                            </div>
                          </div>
                          <div className="cr-date">
                            <div className="heading">date</div>
                            <div className="name">
                              {moment(
                                this.state.selectedDatasetToView[0]
                                  .creation_date
                              ).format("DD MMM YY")}
                            </div>
                          </div>
                        </div>
                        <div className="owner-cr-date">
                          <div className="owner-name">
                            <div className="heading">version</div>
                            <div className="name">
                              {
                                this.state.selectedDatasetToView[0]
                                  .latest_version.version
                              }
                            </div>
                          </div>
                          <div className="cr-date">
                            <div className="heading">rows</div>
                            <div className="name">
                              {
                                this.state.selectedDatasetToView[0]
                                  .latest_version.dataset_records_count
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {this.state.selectedRightPanelAction ===
                    MY_DATASET_RIGHT_PANEL_ACTIONS.ACCESS &&
                  this.props.dataset.datasetSharedWithUsers.length > 0 ? (
                    <>
                      <div className="org bd-sh" style={{ padding: "14px 0" }}>
                        {this.props.dataset.datasetSharedWithUsers.map(
                          (item) => {
                            return (
                              <DatasetAccessComponent
                                key={item.uuid}
                                data={item}
                                datasetId={
                                  this.state.selectedDatasetToView[0].uid
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </>
                  ) : null
                  } */}

                    {this.state.selectedRightPanelAction ===
                      MY_DATASET_RIGHT_PANEL_ACTIONS.STATS && (
                      <>
                        <div className="org bd-sh" style={{ padding: 0 }}>
                          <img src={DummyWidget1} />
                          <img src={DummyWidget2} />
                        </div>
                      </>
                    )}
                    {this.state.selectedRightPanelAction ===
                      MY_DATASET_RIGHT_PANEL_ACTIONS.ACCESS &&
                      (this.props.dataset.datasetSharedWithUsers.length > 0 ? (
                        <>
                          <div
                            className="org bd-sh"
                            style={{ padding: "14px 0" }}
                          >
                            {this.props.dataset.datasetSharedWithUsers.map(
                              (item) => {
                                return (
                                  <DatasetAccessComponent
                                    key={item.uuid}
                                    data={item}
                                    datasetId={
                                      this.state.selectedDatasetToView[0].uid
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="org bd-sh"
                          style={{
                            height: "72px",
                            flexDirection: "row",
                            gap: "8px",
                            fontSize: "14px",
                          }}
                        >
                          <img src={ImgInfoSm} width="24px" height="24px" />{" "}
                          Dataset is not shared with anyone yet.
                        </div>
                      ))}

                    {this.state.selectedRightPanelAction ===
                      MY_DATASET_RIGHT_PANEL_ACTIONS.OVERVIEW && (
                      <div className="col-details bd-sh">
                        <div className="heading">
                          COLUMNS DETAILS{" "}
                          <span>
                            <img
                              title="Preview"
                              src={ImgMystery}
                              className="preview"
                              onClick={() =>
                                this.toggleCollapse("datasetPreview")
                              }
                            />
                            <img
                              src={ImgMoreHoriz}
                              onClick={() =>
                                this.toggleCollapse("columnDetails")
                              }
                            />
                          </span>
                        </div>
                        <DatasetColumnDetailsComponent
                          datasetSchema={this.state.datasetSchema}
                        />
                        {/* <ul>
                          <li className="col-header">
                            <span>columns</span>

                            <span>sample/type</span>
                          </li>
                          {this.getColumnDetails(
                            this.state.selectedDatasetToView[0].latest_version
                              .dataset_schema
                          )}
                        </ul> */}
                      </div>
                    )}
                  </section>
                </div>
              </div>
            )}
            {}
            <DatasetPreviewPopupComponent
              preview_table_data={this.state.preview_table_data}
              preview_table_header={this.state.preview_table_header}
              toggle={() => this.toggleCollapse("datasetPreview")}
              modal={this.state.previewModal}
              onAccept={() => this.toggleCollapse("datasetPreview")}
            />
            {this.state.selectedDatasetToView.length > 0 && (
              <ShareDatasetComponent
                rootDatasetId={this.state.selectedDatasetToView[0].uid}
                toggle={() => this.toggleCollapse("shareDataset")}
                modal={this.state.shareDatasetModal}
                onAccept={() => this.toggleCollapse("shareDataset")}
                onSubmit={
                  this.state.selectedDatasets.length > 1
                    ? this.onShareMultipleDataset
                    : this.onShareDataset
                }
              />
            )}
            {this.state.selectedDatasetToView.length > 0 && (
              <EditShareDatasetComponent
                rootDatasetId={this.state.selectedDatasetToView[0].uid}
                toggle={() => this.toggleCollapse("editShareDataset")}
                modal={this.state.editShareDatasetModal}
                onAccept={() => this.toggleCollapse("editShareDataset")}
                onSubmit={this.onShareDataset}
              />
            )}
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isDatasetsListLoading, rootDatasets, datasetSharedWithUsers } =
    state.dataset;
  return {
    dataset: { isDatasetsListLoading, rootDatasets, datasetSharedWithUsers },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(datasetActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDatasetsComponent);
